import "./index.css";
import images from "./img/*.png";
//import moment from 'moment';
//import 'moment/locale/fr';
import Cookies from "js-cookie/src/js.cookie";

const Uppy = require("@uppy/core");
const XHRUpload = require("@uppy/xhr-upload");
const Dashboard = require("@uppy/dashboard");
const frLocale = require("@uppy/locales/lib/fr_FR");

// And their styles (for UI plugins)
// With webpack and `style-loader`, you can require them like this:
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

class App {
    constructor() {
        // verif si personne identifiée
        if (typeof Cookies.get("AdresaUploadAdfs") == "undefined") {
            var uriAdfsToApp = process.env.host + "/login.php";
            window.location.replace(
                "https://quoi2-9.groupe-telegramme.fr/simplesaml/auth-adfs.php?uriAdfsToApp=" +
                    encodeURI(uriAdfsToApp)
            );
        } else {
            document.querySelector("header").innerHTML = Cookies.get("AdresaUploadAdfs");
        }

        //document.cookie = "adfs=l.gsssuigo"
        //console.log(Cookies.get('adfsd'))

        const uppy = new Uppy({
            debug: true,
            autoProceed: false,
            restrictions: {
                // maxFileSize: 1000000,
                // maxNumberOfFiles: 3,
                // minNumberOfFiles: 2,
                // allowedFileTypes: ['image/*', 'video/*']
            },
            locale: frLocale,
        }).use(Dashboard, {
            trigger: "#select-files",
            target: ".container",
            inline: true,
        });
        uppy.use(XHRUpload, {
            endpoint: process.env.host + "/upload.php",
        });
        //.use(GoogleDrive, { target: Dashboard, companionUrl: 'https://companion.uppy.io' })

        uppy.on("complete", (result) => {
            console.log("successful files:", result.successful);
            console.log("failed files:", result.failed);
        });
        uppy.on("upload-success", (file, response) => {
            console.log(response.body);
        });
    }
}

const app = new App();
